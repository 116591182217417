<template>
  <section class="page form-template">

    <div class="edit">
      <div class="editor">
        <div class="editor-container" :style="{ width: `${editorWidth}px`, height: `${editorHeight}px` }">
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/styles/github-dark.min.css">
          <pre :style="{ width: `${editorWidth}px`, height: `${editorHeight}px` }"><code :style="{ width: `${editorWidth}px`, height: `${editorHeight}px` }" class="language-html" v-html="code"></code></pre>
          <textarea
            ref="editing"
            spellcheck="false"
            v-model="template.content"
            @keydown.tab.prevent="tab"></textarea>
        </div>
      </div>

      <div class="view">
        <iframe sandbox :srcdoc="iframeHTML"></iframe>
      </div>
    </div>

    <div class="actions">
      <div class="inputs">
        <img @click="closeTemplate" class="back" src="@/assets/img/app-collapse-arrow.svg" alt="Go back">
        <app-input
          type="text"
          v-model="template.name"
          placeholder="Template Name"
        />
      </div>
      <div class="buttons">
        <app-button look="tertiary" @click="isModalVisible = true" title="Parameters & settings">
          Parameters & Settings
        </app-button>

        <app-button look="warning" @click="previewTemplate" title="Preview the template" :loading="isRunningPreview" :disabled="!template.content">
          Real Doppio Render
        </app-button>

        <app-button @click="saveTemplate" title="Save the template">
          Save Template
        </app-button>
      </div>
    </div>

    <app-modal :show.sync="isModalVisible">
      <app-button look="tertiary" class="close" @click="isModalVisible = false">Close</app-button>

      <h1>Parameters & Settings</h1>
      <p>
        For more information on templates, you can find it on our <a :href="`${documentationUrl}/guide/cookbook/using-templates.html#custom-parameters`" target="_blank">documentation</a>.
      </p>
      <app-label>Template Id</app-label>
      <span>{{ template.templateId }}</span>

      <app-label>Type</app-label>
      <app-select :options="[{ name:'PDF', label: 'PDF' }, { name:'SCREENSHOT', label: 'Screenshot' }]" v-model="template.renderType"/>

      <app-label>Parameters</app-label>
      <app-input
          type="textarea"
          v-model="template.parameters"
          placeholder="Parameters"
        />

      <app-label>Default data</app-label>
      <app-input
          type="textarea"
          v-model="template.defaultData"
          placeholder="Default Data"
        />
      <app-button @click="saveTemplate()">Save</app-button>
      <span class="delete" v-if="this.$route.name === 'update-template'" @click="deleteTemplate">Delete this template</span>
    </app-modal>
  </section>
</template>

<script>
import templatesApi from '@/services/api/templates';
import renderApi from '@/services/api/render';
import hljs from 'highlight.js';
import utils from '@/services/utils/utils';
import base64 from '@/services/utils/base64';

export default {
  name: 'form-template',
  data() {
    return {
      template: {
        name: '',
        html: '',
        parameters: '',
        renderType: 'PDF',
        defaultData: '',
      },
      isModalVisible: false,
      iframeHTML: '',
      isRunningPreview: false,
      code: '',

      editorWidth: 2000,
      editorHeight: 2000,

      documentationUrl: process.env.VUE_APP_DOC_URL,
    };
  },
  created() {
    this.debounceUpdateIframe = utils.debounce(this.updateIframe, 2000);
  },
  async mounted() {
    // Récupération du template
    await this.getTemplate();

    // Launch de l editeur
    this.code = hljs.highlight(this.template.content, { language: 'html' }).value;
    this.iframeHTML = this.template.content;
    this.editorWidth = this.$refs.editing.scrollWidth;
    this.editorHeight = this.$refs.editing.scrollHeight + 1000;
    hljs.highlightAll();
  },
  watch: {
    'template.content': function watchContent(val) {
      this.code = hljs.highlight(val, { language: 'html' }).value;
      this.debounceUpdateIframe();

      this.editorWidth = this.$refs.editing.scrollWidth;
      this.editorHeight = this.$refs.editing.scrollHeight + 1000;
    },
  },
  methods: {
    displayDateTime() {
      const now = new Date();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const year = now.getFullYear();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');

      return `${month}/${day}/${year} ${hours}:${minutes}`;
    },
    tab() {
      document.execCommand('insertText', false, '    ');
    },
    setTemplate(newTemplate) {
      // On décode le contenu du template
      this.template = {
        ...newTemplate,
        content: base64.convertBase64ToText((newTemplate.content)),
        parameters: newTemplate.parameters ? JSON.stringify(newTemplate.parameters) : null,
        defaultData: newTemplate.defaultData ? JSON.stringify(newTemplate.defaultData) : null,
      };

      this.code = hljs.highlight(this.template.content, { language: 'html' }).value;
      this.iframeHTML = this.template.content;
    },
    async getTemplate() {
      try {
        const tmpTemplate = await templatesApi.getOneTemplate(this.$route.params.templateId);

        this.setTemplate(tmpTemplate);
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: 'Impossible to fetch the template',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    async saveTemplate() {
      // On vérifie que le nom du template est bien renseigné
      if (!this.template.name) {
        this.$message.show({
          title: 'Error',
          text: 'Name is required',
          confirmText: 'Ok',
          hasCancel: false,
        });
        return;
      }

      // On vérifie que le contenu HTML du template est bien renseigné
      if (!this.template.content) {
        this.$message.show({
          title: 'Error',
          text: 'HTML template is required',
          confirmText: 'Ok',
          hasCancel: false,
        });
        return;
      }

      // On vérifie qu'il est bien possible de parser les paramètres
      let parsedParameters = this.template.parameters;
      let parsedDefaultData = this.template.defaultData;
      try {
        if (this.template.parameters) {
          parsedParameters = JSON.parse(this.template.parameters);
        }
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: 'Parameters Format is invalid',
          confirmText: 'Ok',
          hasCancel: false,
        });
        return;
      }

      try {
        if (this.template.defaultData) {
          parsedDefaultData = JSON.parse(this.template.defaultData);
        }
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: 'Default Data Forme is invalid',
          confirmText: 'Ok',
          hasCancel: false,
        });
        return;
      }

      try {
        // On construit le template à envoyer
        // l'HTML en base64
        // les paramètres en JSON et qui sont optionnels donc si ils sont à null, on envoie rien
        const tmpTemplate = {
          name: this.template.name,
          content: base64.convertTextToBase64(this.template.content),
          renderType: this.template.renderType,
          ...(parsedDefaultData && { defaultData: parsedDefaultData }),
          ...(parsedParameters && { parameters: parsedParameters }),
        };

        // on met à jour le template
        const updatedTemplate = await templatesApi.updateTemplate(this.$route.params.templateId, tmpTemplate);
        this.setTemplate(updatedTemplate);

        this.$notification.show({
          title: 'Success',
          text: 'Template has been updated!',
        });
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: `Impossible to update the template. Please check that your HTML is valid and that your parameters correspond to the documentation \n\n${error.response.data.message}`,
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    async deleteTemplate() {
      try {
        this.$message.show({
          title: 'Delete template?',
          text: 'Are you sure to delete this template?',
          confirmText: 'Yes',
          cancelText: 'No',
          hasCancel: true,
          onConfirm: async () => {
            await templatesApi.deleteTemplate(this.$route.params.templateId);
            this.$notification.show({
              text: 'Template has been deleted!',
            });
            this.$router.push({ name: 'templates' });
          },
        });
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: 'Impossible to delete the template',
          confirmText: 'Ok',
          hasCancel: false,
        });
      }
    },
    async previewTemplate() {
      this.isRunningPreview = true;

      // Appel API Doppio pour un render direct
      try {
        // Récupération de l'URL de la preview (appel à Doppio)
        const render = await renderApi.previewTemplate(this.$route.params.templateId);

        // Ouverture du PDF dans un nouvel onglet
        const fileUrl = render.documentUrl;
        window.open(fileUrl, '_blank', 'noreferrer');
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: 'Impossible to preview the template',
          confirmText: 'Ok',
          hasCancel: false,
        });
      } finally {
        this.isRunningPreview = false;
      }
    },
    closeTemplate() {
      this.$message.show({
        title: 'Return to Templates?',
        text: 'Are you sure you want to exit the template editor and go back to the Templates page? \n\nDon’t forget to save your changes!',
        confirmText: 'Yes',
        hasCancel: true,
        onConfirm: () => {
          this.$router.push({ name: 'templates' });
        },
      });
    },
    updateIframe() {
      this.iframeHTML = this.template.content;
    },
  },
};
</script>

<style lang="sass">
.form-template
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  display: flex
  padding: 70px 1.5rem 1rem
  background-color: $primary100
  z-index: 995
  overflow: hidden
  .modal-wrapper
    padding: 2rem
    min-width: 60vw
    max-height: 100vh
    overflow-y: scroll
    background: $primary95
    color: white
    .modal-content
      position: relative
    .close
      position: absolute
      top: -2rem
      right: 1rem
    .delete
      position: absolute
      bottom: 2rem
      right: 1rem
      text-decoration: underline
      cursor: pointer
    h1
      color: white
    p
      margin: 2rem 0
    .app-select
      max-width: 250px
    .app-label
      margin-top: 2rem
    .app-button
      margin-top: 3rem
    textarea
      min-height: 150px
  .actions
    position: fixed
    top: 0
    left: 0
    right: 0
    display: flex
    flex-direction: row
    justify-content: space-between
    padding: 1rem 1.5rem
    background-color: $primary100
    .inputs
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      flex: 1
      .back
        max-width: 30px
        max-height: 30px
        transform: rotate(90deg) translateY(5px)
        cursor: pointer
      .app-input
        width: 100%
        &:first-child
          width: 400px
        & ~ .app-input
         margin-left: 1rem
    .buttons
      display: flex
      flex-direction: row
      justify-content: space-between
      .app-button
        margin-left: 1rem
  .edit
    display: flex
    flex-direction: row
    flex: 1
    > div
      display: flex
      width: calc(50vw - 1.5rem)
  iframe
    flex: 1
    margin-left: 1rem
    border: none
    background: #AAA0D4
    border-radius: 4px
  .editor
    overflow: scroll
    scrollbar-width: thin
    scrollbar-color: black transparent
    background: #322D48
    border-radius: $global-border-radius

    .editor-container
      position: relative
      width: 2000px
      height: 6000px
      background: #322D48
      pre
        width: 2000px
        height: 6000px
        margin: 0
        overflow: hidden
      code
        width: 2000px
        height: 6000px
        background: #322D48
      textarea
        all: unset
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        margin-top: 1px
        padding: 1em
        width: 100%
        height: 100%
        background: transparent
        font-family: monospace
        white-space-collapse: preserve
        text-wrap: nowrap
        white-space: pre
        color: transparent
        caret-color: #fff
        overflow: hidden

</style>
