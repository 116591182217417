import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const previewTemplate = async (templateId) => {
  try {
    const response = await axios.post(`${API_URL}/dashboard/templates/${templateId}/preview`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.previewTemplate = previewTemplate;

export default api;
